import React from 'react';

import { asyncWrap } from '../api';
import { fetchUserAlerts, utils } from '../helpers';
import { ProductSelectorModalContextProvider, ProductSelectorModalContextState } from '../pages/campaigns/builder/components/ProductSelectorModal';
import {
	AccountsContext,
	AccountsContextState,
	AlertsContext,
	AlertsContextState,
	AllStoresContext,
	AllStoresContextState,
	CampaignsContext,
	CampaignsContextState,
	CampaignTagsContext,
	CampaignTagsContextState,
	CampaignTemplatesContext,
	CampaignTemplatesContextState,
	DefaultCampaignTemplatesContext,
	DefaultCampaignTemplatesContextState,
	DiscountsContext,
	DiscountsContextState,
	DrawerStateContext,
	FailSafeContext,
	LoginsContext,
	LoginsContextState,
	PublicStoresContext,
	PublicStoresContextState,
	Role,
	SidebarCollapseContext,
	SurveysContext,
	SurveysContextState,
	ThirdPartyStoresContext,
	ThirdPartyStoresContextState,
	UserStoresContext,
	UserStoresContextState,
} from '../types';
import useQuery from './UseQuery';

type useContectProps = {
	uid: string;
	noLayout?: boolean;
	children: React.ReactNode;
};

export const SidebarCollapseContextProvider = React.createContext<SidebarCollapseContext | {}>({});
export const DrawerStateContextProvider = React.createContext<DrawerStateContext | {}>({});
export const AlertsContextProvider = React.createContext<AlertsContext | {}>({});
export const CampaignsContextProvider = React.createContext<CampaignsContext | []>([]);
export const DiscountsContextProvider = React.createContext<DiscountsContext | []>([]);
export const AllStoresContextProvider = React.createContext<AllStoresContext | []>([]);
export const PublicStoreContextProvider = React.createContext<PublicStoresContext | []>([]);
export const UserStoresContextProvider = React.createContext<UserStoresContext | []>([]);
export const ThirdPartyStoresContextProvider = React.createContext<ThirdPartyStoresContext | []>([]);
export const AccountsContextProvider = React.createContext<AccountsContext | []>([]);
export const CampaignTemplatesContextProvider = React.createContext<CampaignTemplatesContext | []>([]);
export const DefaultCampaignTemplatesContextProvider = React.createContext<DefaultCampaignTemplatesContext | []>([]);
export const SurveysContextProvider = React.createContext<SurveysContext | []>([]);
export const CampaignTagsProvider = React.createContext<CampaignTagsContext | []>([]);
export const LoginsContextProvider = React.createContext<LoginsContext | []>([]);
export const FailsafeContextProvider = React.createContext<FailSafeContext | []>([]);

export default function ContextProvider(props: useContectProps) {
	const query = useQuery();
	const [plannerDrawerOpen, setPlannerDrawerOpen] = React.useState<boolean>(false);
	const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState<boolean>(false);
	const [featureHighlightDrawerOpen, setFeatureHighlightDrawerOpen] = React.useState<string | undefined>(undefined);
	const [userAlertManagerOpen, _setUserAlertManagerOpen] = React.useState<boolean>(query.get('userAlertManager') === 'true');
	const setUserAlertManagerOpen = (open: boolean) => {
		if (open) setNotificationDrawerOpen(false);
		const url = new URL(window.location.href);
		if (!open) url.searchParams.delete('userAlertManager');
		else url.searchParams.set('userAlertManager', 'true');
		window.history.pushState({}, '', url.toString());
		_setUserAlertManagerOpen(open);
	};

	// Initilixe states here
	const [alerts, setAlerts] = React.useState<AlertsContextState>({});
	const [campaigns, setCampaigns] = React.useState<CampaignsContextState>({});
	const [discounts, setDiscounts] = React.useState<DiscountsContextState>({});
	const [allStores, setAllStores] = React.useState<AllStoresContextState>({});
	const [publicStores, setPublicStores] = React.useState<PublicStoresContextState>({});
	const [userStores, setUserStores] = React.useState<UserStoresContextState>({});
	const [thirdPartyStores, setThirdPartyStores] = React.useState<ThirdPartyStoresContextState>({});
	const [accounts, setAccounts] = React.useState<AccountsContextState>({});
	const [campaignTemplates, setCampaignTemplates] = React.useState<CampaignTemplatesContextState>({});
	const [defaultCampaignTemplates, setDefaultCampaignTemplates] = React.useState<DefaultCampaignTemplatesContextState>({});
	const [surveys, setSurveys] = React.useState<SurveysContextState>({});
	const [ecomData, setEcomData] = React.useState<ProductSelectorModalContextState>({ ecomBrands: [], ecomCategories: [] });
	const [campaignTags, setCampaignTags] = React.useState<CampaignTagsContextState>({});
	const [logins, setLogins] = React.useState<LoginsContextState>({});
	const [failsafe, setFailsafe] = React.useState<boolean>(false);

	const [collapsed, _setCollapsed] = React.useState(localStorage.getItem('collapsed') === 'true');
	const setCollapsed = (collapsed: boolean, persist = true) => {
		if (persist) localStorage.setItem('collapsed', collapsed ? 'true' : 'false');
		else localStorage.removeItem('collapsed');
		_setCollapsed(collapsed);
	};

	React.useEffect(() => {
		if (notificationDrawerOpen) {
			fetchUserAlerts(props.uid, [alerts, setAlerts], 1, props.noLayout);
		}
	}, [notificationDrawerOpen]);

	React.useEffect(() => {
		if (!alerts.updated && !notificationDrawerOpen)
			asyncWrap(async () => {
				const role = utils.auth.getRole();
				const isStaffAppPage = utils.strify(location.pathname).includes('walletsearch');
				const isNotBudtender = [Role.MARKETING, Role.MA, Role.OPERATOR, Role.OWNER, Role.ANALYST].includes(role);
				const isNotAgencyPage = !utils.strify(location.pathname).includes('/agency');

				// start the alerts update queue if the are of the correct role
				if ((utils.isAdmin() || isStaffAppPage || isNotBudtender) && isNotAgencyPage) {
					fetchUserAlerts(props.uid, [alerts, setAlerts], 10, props.noLayout);
				}
			});
	}, []);

	// Wrap children with providers
	return (
		<>
			<SidebarCollapseContextProvider.Provider value={[collapsed, setCollapsed]}>
				<DrawerStateContextProvider.Provider
					value={{
						plannerDrawerOpen,
						setPlannerDrawerOpen,
						notificationDrawerOpen,
						setNotificationDrawerOpen,
						featureHighlightDrawerOpen,
						setFeatureHighlightDrawerOpen,
						userAlertManagerOpen,
						setUserAlertManagerOpen,
					}}
				>
					<AlertsContextProvider.Provider value={[alerts, setAlerts]}>
						<CampaignsContextProvider.Provider value={[campaigns, setCampaigns]}>
							<AllStoresContextProvider.Provider value={[allStores, setAllStores]}>
								<PublicStoreContextProvider.Provider value={[publicStores, setPublicStores]}>
									<UserStoresContextProvider.Provider value={[userStores, setUserStores]}>
										<ThirdPartyStoresContextProvider.Provider value={[thirdPartyStores, setThirdPartyStores]}>
											<AccountsContextProvider.Provider value={[accounts, setAccounts]}>
												<CampaignTemplatesContextProvider.Provider value={[campaignTemplates, setCampaignTemplates]}>
													<DefaultCampaignTemplatesContextProvider.Provider value={[defaultCampaignTemplates, setDefaultCampaignTemplates]}>
														<DiscountsContextProvider.Provider value={[discounts, setDiscounts]}>
															<SurveysContextProvider.Provider value={[surveys, setSurveys]}>
																<ProductSelectorModalContextProvider.Provider value={[ecomData, setEcomData]}>
																	<CampaignTagsProvider.Provider value={[campaignTags, setCampaignTags]}>
																		<LoginsContextProvider.Provider value={[logins, setLogins]}>
																			<FailsafeContextProvider.Provider value={[failsafe, setFailsafe]}>{props.children}</FailsafeContextProvider.Provider>
																		</LoginsContextProvider.Provider>
																	</CampaignTagsProvider.Provider>
																</ProductSelectorModalContextProvider.Provider>
															</SurveysContextProvider.Provider>
														</DiscountsContextProvider.Provider>
													</DefaultCampaignTemplatesContextProvider.Provider>
												</CampaignTemplatesContextProvider.Provider>
											</AccountsContextProvider.Provider>
										</ThirdPartyStoresContextProvider.Provider>
									</UserStoresContextProvider.Provider>
								</PublicStoreContextProvider.Provider>
							</AllStoresContextProvider.Provider>
						</CampaignsContextProvider.Provider>
					</AlertsContextProvider.Provider>
				</DrawerStateContextProvider.Provider>
			</SidebarCollapseContextProvider.Provider>
		</>
	);
}
